import React from "react"
import Layout from "../../../components/layout"
import Clutch from "../../../components/clutch"
import {Helmet} from "react-helmet/es/Helmet";

export default () => (
    <Layout>
        <Helmet>
            <title>CueBlocks Team - Ecommerce Experts behind the Scenes</title>
            <meta name="description"
                  content="CueBlocks has been designing, building and marketing Ecommerce stores since 2005. Meet our team who transform these online stores into awesome websites." />
            <meta name="viewport" content="initial-scale=1.0, width=device-width, maximum-scale=1, user-scalable=no"/>
            <link rel="canonical" href="https://www.cueblocks.com/team.php"/>
            <meta name="description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:site_name" content="Cueblocks"/>
            <meta property="fb:app_id" content="289086684439915"/>
            <meta property="og:url" content="https://www.cueblocks.com/team.php"/>
            <meta property="og:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta property="og:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta property="og:image" content="https://www.cueblocks.com/images/cb.jpg"/>

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:description"
                  content="CueBlocks is a Full Service eCommerce Agency based out of Chandigarh, India & Brno, Czech Republic. We help small to medium sized online retailers maximize their returns on Magento 2, Shopify, Wordpress + WooCommerce and PHP Development."/>
            <meta name="twitter:title"
                  content="CueBlocks: eCommerce + Digital Marketing Agency | Magento2, Shopify, WooCommerce, PHP development & eCommerce Digital Marketing."/>
            <meta name="twitter:site" content="@cueblocks"/>
            <meta name="twitter:image" content="https://www.cueblocks.com/images/cb.jpg"/>
        </Helmet>
        <div className="cb_expertiseInner">
            <div className="services-inner services-custom">


                <h1>SEARCH ENGINE OPTIMIZATION</h1>

                <h2> More Google love for your website :)</h2>
                <br />
                <p>Search engines love optimized websites but not those that take it a bit too far! We'll help you to not just build
                but optimize a website to get more traffic, better visibility and higher rankings.
                <br />
                    <br /> CueBlocks.com is a full-service digital agency offering Search Engine Optimization services and strategies that
                    are Panda/ Penguin proof and are search engine friendly. We begin by carefully chalking out areas of improvement on
                    your website and carefully work out an optimization strategy that best suits your kind of business.</p>
                <br />
                <br />
                <h2>Our SEO methodology includes:</h2>
                <ul className="search_em">
                    <li><strong>Website Analysis</strong> - We analyze the website and make a holistic report referring to the website's
                        data, its weaknesses and strengths. The report includes the information such as broken links, poor usage of Meta
                    tags, data backup, security issues that affect the search engine rankings of your web pages.</li>
                    <li><strong>Keyword Research </strong>- We carry out an extensive keyword analysis to increase the traffic and
                        conversion rate of your website. Our comprehensive keyword research services aim to map the right keywords for
                    your business which can maximize your Search Engine Marketing Return on Investment (ROI).</li>
                    <li> <strong>Link Building </strong>- We provide effective SEO link building services that ensure that your website
                        gets the top search engine ranking on Google and appears on the first page of search results. Link Building is one
                    of the pre-eminent ways to drive traffic to your website. </li>
                    <li><strong>Content Optimization </strong>- We like to view content optimization as not much different from content
                        creation. We believe well optimized content or well written content isn't only keyword rich but in fact engaging.
                        Keeping this in mind we create content that is not only descriptive, SEO compliant but also value-adding to the
                    reader. </li>
                    <li> <strong>Onpage Optimization </strong>- Our SEO Onpage optimization includes an integrative optimization of the
                        website which includes body content optimization, title tag optimization, meta tags optimization, header tags and
                    image optimization.</li>
                </ul>
                <p>With our effective SEO solutions, you can enhance your online presence and work on improving your conversion rate.</p>



                <div className="get-touch">
                    <p>like what you see?</p>

                    <a href="/contact" className="main_cta">Get in touch</a>
                </div>

                <div className="get-touch learn-casestudy">
                    <h3>Learn how we helped one of our clients derive maximum value from their existing blogs</h3>
                    <a href="seo/case-study/merging-blog-pages-seo.html" className="main_cta">Case Study</a>
                </div>

            </div>
        </div>

        <Clutch />

    </Layout>

)